<template>
    <v-form
            ref="form">
    <v-text-field
            v-model="value.name"
            label="Name"
            :rules="nameRules"
            required
    ></v-text-field>
    <v-text-field
            v-model="value.companyName"
            label="Firmenname"
            :rules="companyNameRules"
            required
    ></v-text-field>
    <v-text-field
            v-model="value.address"
            :rules="addressRules"
            label="Adresse"
            required
    ></v-text-field>
    <v-text-field
            v-model="value.floor"
            :rules="floorRules"
            label="Etage"
    ></v-text-field>
    <v-text-field
            v-model="value.city"
            :rules="cityRules"
            label="Stadt"
            required
    ></v-text-field>
    <v-text-field
            maxlength="250"
            v-model="value.zipCode"
            :rules="zipCodeRules"
            label="Postleitzahl"
            required
    ></v-text-field>
    </v-form>
</template>

<script>
    import validateAddressMixin from "../mixins/validateAddressMixin";
    export default {
        name: "CompactAddressInput",
        mixins:[validateAddressMixin]
    }
</script>

<style lang="sass">
    .v-messages__message
        color: #ff4a4a

</style>