<template>
    <div>
        <div v-if="((hasCheckedOrders && !showRestaurant) || (showRestaurant)) && !loading">
        <div v-for="order in ordersToShow" :key="order.id" class="d-print-none" >
            <v-card
                    class="mx-auto mb-1 mt-1"
                    @click="handleClick(order)"
                    :color="(showRestaurant && (!order.hasOwnProperty('accepted') || !order.accepted)) ? 'red lighten-5' : ''"
                            >
                <v-card-text>
                    <div class="text--primary pb-0 mb-0 mt-0 pt-0"  :class="{'headline font-weight-bold': today, 'subtitle-1': !today}">{{order.timestamp.toLocaleTimeString()}}<span v-if="!today"> - {{order.timestamp.toLocaleDateString()}}</span></div>
                    <p class="subtitle-1 text--primary pb-0 mb-0 mt-0 pt-0">
                        {{order.totalPrice/100 | currency}} / <span v-if="showRestaurant">{{order.address.name}} /</span>{{order.address.address}} / {{order.address.city}}
                    </p>
                    <div class="d-inline" v-for="(item, index) in order.items" :key="item.foodId + index">
                        {{item.name}}
                        <div class="d-inline" v-if="index < order.items.length-1">, </div>
                    </div>
                </v-card-text>
            </v-card>
        </div>
            <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly" id="order-detail" max-width="650">
                <v-card
                        :color="(showRestaurant && selectedOrder && (!selectedOrder.hasOwnProperty('accepted') || !selectedOrder.accepted)) ? 'red lighten-5' : ''">
                    <v-card-title class="pb-0 pr-3 pt-3">
                        <div class="pl-3" v-if="today && selectedOrder && (!selectedOrder.hasOwnProperty('accepted') || !selectedOrder.accepted)">Diese Bestellung ist noch NICHT akzeptiert.</div>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog = false"><v-icon>{{ mdiClose }}</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container class="pt-0">
                        <order-detail print-button :order="selectedOrder"></order-detail>
                        </v-container>
                    </v-card-text>
                    <v-card-actions v-if="selectedOrder && today && ((selectedOrder.hasOwnProperty('accepted') && selectedOrder.accepted === false) || !selectedOrder.hasOwnProperty('accepted'))">
                        <v-btn block @click="deliveryTimeDialog = true" color="success">Bestellung akzeptieren</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
          <v-dialog v-model="deliveryTimeDialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="300">
            <v-card>
              <v-card-title class="pb-0 pr-3 pt-3">
                <div >Lieferdauer in Minuten</div>
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                 <v-col class="pt-2" cols="12" ><v-btn block text @click="accept(20)">20</v-btn></v-col>
                  <v-col class="pt-2" cols="12" ><v-btn block text @click="accept(30)">30</v-btn></v-col>
                  <v-col class="pt-2" cols="12" ><v-btn block text @click="accept(45)">45</v-btn></v-col>
                  <v-col class="pt-2" cols="12" ><v-btn block text @click="accept(60)">60</v-btn></v-col>
                  <v-col class="pt-2" cols="12" ><v-btn block text @click="accept(90)">90</v-btn></v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-dialog>

            <v-btn class="mt-5" block v-if="lastOrder !== null && !showRestaurant"  @click="fetchMoreBookings" :disabled="waitingForMore">{{waitingForMore ? '...' : 'Weitere Buchungen laden'}}</v-btn>
            <div class="pa-2" v-if="ordersToShow.length===0 && !today">Keine Bestellungen vorhanden.</div>
            <div class="pa-2" v-if="ordersToShow.length===0 && today">Warte auf Bestellungen...</div>
        </div>
        <v-layout align-center justify-center class="pt-12 pb-9" v-else>
            <v-progress-circular
                    indeterminate
                    size="85"
                    color="primary"
            ></v-progress-circular>
        </v-layout>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import OrderDetail from "./OrderDetail";
    import { mdiClose} from '@mdi/js'


    export default {
        name: "OrderOverview",
        components: {OrderDetail},
        props: {
            showRestaurant: Boolean,
            today: Boolean,
            date: Date
        },
        computed: {
            ...mapGetters({
                orders: 'orderStore/sortedOrders',
                lastOrder:'orderStore/lastOrder',
                restaurantOrders: 'orderStore/sortedRestaurantOrders',
                hasCheckedOrders: 'orderStore/hasCheckedOrders',
                loggedIn: 'userStore/loggedIn',
                hasCheckedLogin: 'userStore/hasCheckedLogin'
            }),
            ordersToShow() {
                if (this.showRestaurant){
                    if(this.today && !this.date){
                        return this.restaurantOrders(new Date(new Date().setHours(0, 0 ,0 ,0)))
                    } else {
                        return this.restaurantOrders(this.date)
                    }
                } else {
                    return this.orders()
                }
            }
        },
        data() {return{
            mdiClose:mdiClose,
            dialog:false,
            selectedOrder: undefined,
            waitingForMore: false,
            loading: false,
          deliveryTimeDialog: false,
        }},
        methods: {
            ...mapActions({
                fetchPaginatedOrders: 'orderStore/fetchPaginatedOrders',
                fetchRestaurantOrdersByDay: 'orderStore/fetchRestaurantOrdersByDay',
                attachTodaysRestaurantOrders: 'orderStore/attachTodaysRestaurantOrders',
                acceptOrder:'orderStore/acceptOrder'
            }),
            handleClick(order){
                this.selectedOrder = order
                this.dialog = true
            },
            accept(time){
                this.dialog=false
                this.deliveryTimeDialog = false
                this.acceptOrder({id:this.selectedOrder.id, estimatedDeliveryTime: time})
            },
            fetchMoreBookings(){
                this.waitingForMore = true
                this.fetchPaginatedOrders().then(()=>{
                    this.waitingForMore = false
                }).catch(err=>{console.log(err)})

            }
        },
        created() {
            if (this.loggedIn && !this.hasCheckedOrders && !this.showRestaurant) {
                this.fetchPaginatedOrders()
            } else if (this.loggedIn && this.showRestaurant && this.today) {
                this.attachTodaysRestaurantOrders()
            } else if (this.loggedIn && this.showRestaurant && this.date) {
                this.loading = true
                this.fetchRestaurantOrdersByDay(this.date).then(()=>{
                    this.loading = false
                })
            }
        },
        watch: {
            loggedIn(val) {
                if (val && !this.hasCheckedOrders && !this.showRestaurant) {
                    this.fetchPaginatedOrders()
                } else if (val && this.showRestaurant && this.today) {
                    this.attachTodaysRestaurantOrders()
                } else if (val && this.showRestaurant && this.date) {
                    this.loading = true
                    this.fetchRestaurantOrdersByDay(this.date).then(()=>{
                        this.loading = false
                    })
                }
            },
            date(val){
                if(val && this.showRestaurant && this.loggedIn){
                    this.loading = true
                    this.fetchRestaurantOrdersByDay(this.date).then(()=>{
                        this.loading = false
                    })
                }
            }
        }
    }
</script>

<style scoped lang="sass">
    @media print
    .order-detail
        width: 100vw
        height: 100vh
        position: fixed
        top: 0
        left: 0
        background-color: purple

</style>