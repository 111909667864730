import Vue from 'vue'
export default {
    data() {
        return {
            addressRules: [],
            floorRules: [],
            cityRules: [],
            zipCodeRules: [],
            nameRules: [],
            companyNameRules: [],
            phoneNumberRules: [],
            emailRules:[]

        }
    },
    props: {
        value: Object,
        address: Object,
        validate: Boolean,
        zipCodes: Array,
        withPhoneNumber: Boolean,
        phoneNumber: String,
        withEmail: Boolean,
        email: String
    },
    computed: {
        valid() {
            if (
                this.value.address && this.value.address.length < 100 && this.value.address.length > 0 &&
                this.value.city && this.value.city.length < 100 && this.value.city.length > 0 &&
                this.value.zipCode && this.value.zipCode.length === 5 && /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(this.value.zipCode) &&
                this.value.name && this.value.name.length < 200 && this.value.name.length > 0 &&
                this.value.companyName.length < 100 &&
                this.value.floor.length < 100 &&
                (!this.withPhoneNumber || (this.value.phoneNumber && this.value.phoneNumber.length < 20 && this.value.phoneNumber.length > 3)) &&
                //eslint-disable-next-line
                (!this.withEmail || (this.value.email && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.value.email))) &&
                (!this.zipCodes || this.zipCodes.includes(this.value.zipCode))
            ) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        validate(value) {
            if (value) {
                this.addressRules = [
                    v => !!v || 'Die Adresse ist notwendig',
                    v => (v && v.length <= 100) || 'Die Adresse ist zu lang',
                ];
                this.cityRules = [
                    v => !!v || 'Der Name der Stadt ist notwendig',
                    v => (v && v.length <= 100) || 'Der Name der Stadt ist zu lang',
                ];
                this.zipCodeRules = [
                    v => !!v || 'Die Postleitzahl ist notwendig',
                    v => /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(v) || 'Die Postleitzahl ist nicht gültig',
                ];
                if (this.zipCodes) {
                    this.zipCodeRules.push(
                        v => (v && this.zipCodes.includes(v)) || 'Die Postleitzahl ist nicht in dem Liefergebiet'
                    )
                }
                this.nameRules = [
                    v => !!v || 'Der Name ist notwendig',
                ];
                this.companyNameRules = [
                    v => (v.length <= 100) || 'Der Name der Firma ist zu lang',
                ];
                this.floorRules = [
                    v => (v.length <= 100) || 'Die Eingabe für die Etage ist zu lang',
                ];
                this.phoneNumberRules = [
                    v => !!v || 'Die Telefonnummer ist notwendig!',
                    v => (v.length <= 20) || 'Die Eingabe für die Telefonnummer ist zu lang',
                ];
                this.emailRules = [
                    v => !!v || 'Die E-Mail Adresse ist notwendig!',
                    //eslint-disable-next-line
                    v =>  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Die E-Mail Adresse ist nicht gültig!'
                ]
                this.$refs.form.validate()
                Vue.nextTick().then(()=>{
                    if(!this.valid){
                        window.scrollTo(0,0)
                    }
                })
            } else {
                this.addressRules = [];
                this.cityRules = [];
                this.zipCodeRules = [];
                this.nameRules = [];
                this.companyNameRules = [];
                this.floorRules = [];
                this.phoneNumberRules = [];
                this.emailRules = [];
            }
        },
        value: {
            handler() {
                this.$emit('input', this.value)
            },
            deep: true
        },
        valid(value) {
            this.$emit('validityChanged', value)
        },
        address(address) {
            if (address) {
                this.value.id = address.id
                this.value.address = address.address
                this.value.floor = address.floor
                this.value.city = address.city
                this.value.zipCode = address.zipCode
                this.value.name = address.name
                this.value.companyName = address.companyName
            } else {
                delete this.value.id
                this.value.address = ''
                this.value.floor = ''
                this.value.city = ''
                this.value.zipCode = ''
                this.value.name = ''
                this.value.companyName = ''
            }

        },
        phoneNumber(number) {
            if (number) {
                this.value.phoneNumber = number
            } else {
                this.value.phoneNumber = ""
            }
        },
        email(newMail) {
            if (newMail) {
                this.value.email = newMail
            } else {
                this.value.email = ""
            }
        }
    },
    created() {
        if (this.address) {
            this.value.id = this.address.id
            this.value.address = this.address.address
            this.value.floor = this.address.floor
            this.value.city = this.address.city
            this.value.zipCode = this.address.zipCode
            this.value.name = this.address.name
            this.value.companyName = this.address.companyName
        }
        if (this.phoneNumber) {
            this.value.phoneNumber = this.phoneNumber
        }
        if (this.email) {
            this.value.email = this.email
        }
        if (this.validate) {
            this.addressRules = [
                v => !!v || 'Die Adresse ist notwendig',
                v => (v && v.length <= 100) || 'Die Adresse ist zu lang',
            ];
            this.cityRules = [
                v => !!v || 'Der Name der Stadt ist notwendig',
                v => (v && v.length <= 100) || 'Der Name der Stadt ist zu lang',
            ];
            this.zipCodeRules = [
                v => !!v || 'Die Postleitzahl ist notwendig',
                v => (v && v.length <= 15) || 'Die Postleitzahl ist zu lang',
                v => /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(v) || 'Die Postleitzahl ist nicht gültig',
            ];
            this.nameRules = [
                v => !!v || 'Der Name ist notwendig',
            ];
            this.companyNameRules = [
                v => (v.length <= 100) || 'Der Name der Firma ist zu lang',
            ];
            this.floorRules = [
                v => (v.length <= 100) || 'Die Eingabe für die Etage ist zu lang',
            ];
            this.phoneNumberRules = [
                v => !!v || 'Die Telefonnummer ist notwendig!',
                v => (v.length <= 20) || 'Die Eingabe für die Telefonnummer ist zu lang',
            ];
            this.emailRules = [
                v => !!v || 'Die E-Mail Adresse ist notwendig!',
                //eslint-disable-next-line
                v =>  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
            ]
        }
        if (!this.withPhoneNumber) {
            delete this.value.phoneNumber
        }
        if (!this.withEmail) {
            delete this.value.email
        }
        this.$emit('validityChanged', this.valid)
    }
};