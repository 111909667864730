<template>
    <v-container>
        <v-card v-if="loggedIn" min-height="400">
            <v-tabs :fixed-tabs="this.$vuetify.breakpoint.smAndDown" :vertical="this.$vuetify.breakpoint.lgAndUp" v-model="activeTab">
                <v-tab>
                    <v-icon left>{{ mdiHomeCity }}</v-icon>
                    <span v-if="this.$vuetify.breakpoint.smAndUp">Adressen</span>
                </v-tab>
                <v-tab>
                    <v-icon left>{{ mdiPhone }}</v-icon>
                    <span v-if="this.$vuetify.breakpoint.smAndUp">Telefon </span>
                </v-tab>
                <v-tab>
                    <v-icon left>{{ mdiFood }}</v-icon>
                    <span v-if="this.$vuetify.breakpoint.smAndUp">Bestellungen</span>
                </v-tab>

                <v-tab-item>
                    <v-card flat>
                      <v-card-title v-if="this.$vuetify.breakpoint.xsOnly">Meine Adressen</v-card-title>
                      <v-card-text>
                          <address-overview></address-overview>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                      <v-card-title v-if="this.$vuetify.breakpoint.xsOnly">Meine Telefonnummer</v-card-title>

                      <v-card-text>
                            <phone-selector v-if="activeTab===1"></phone-selector>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                      <v-card-title v-if="this.$vuetify.breakpoint.xsOnly">Meine Bestellungen</v-card-title>

                      <v-card-text>
                            <order-overview v-if="activeTab===2">
                            </order-overview>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'
    import PhoneSelector from "@/components/PhoneSelector";
    import OrderOverview from "@/components/OrderOverview";
    import AddressOverview from "@/components/AddressOverview";
    import { mdiHomeCity, mdiPhone, mdiFood } from '@mdi/js'


    export default {
        name: "profile",
        data() {
            return {
                mdiHomeCity:mdiHomeCity,
                mdiPhone:mdiPhone,
                mdiFood:mdiFood,
                activeTab: 0,
            }
        },
        computed: {
            ...mapGetters({
                hasCheckedLogin: 'userStore/hasCheckedLogin',
                loggedIn: 'userStore/loggedIn',
            })
        },
        components: {
            AddressOverview,
            OrderOverview,
            PhoneSelector
        },
        watch:{
            hasCheckedLogin(value){
                if(value && !this.loggedIn){
                    this.$router.push({name:'index'})
                }
            },
            loggedIn(value){
                if(!value){
                    this.$router.push({name:'index'})
                }
            }
        },
        created(){
            if(this.hasCheckedLogin && !this.loggedIn){
                this.$router.push({name:'index'})
            }
        }
    }
</script>

<style scoped>

</style>
