<template>
  <div>
    <v-row no-gutters class="d-inline">
      <div v-if="user.hasCheckedLogin">
        <p v-if="!noCurrentTelString && !phoneNumberLoading">{{phoneNumber ? 'Telefonnummer:' + phoneNumber:'Keine Telefonnummer'}}</p>
        <v-layout align-center class="pt-0 pb-4 pl-4" v-if="phoneNumberLoading">
          <v-progress-circular
              indeterminate
              size="20"
              color="primary"
          ></v-progress-circular>
        </v-layout>
        <v-btn text @click="dialog = true">
          {{ buttonText }}
        </v-btn>
        <v-btn v-if="phoneNumber" class="ml-2"
               color="red lighten-2"
               dark
               @click="deletePhoneNumber"
        >
          Entfernen
        </v-btn>
      </div>
      <v-layout align-center justify-center class="pt-5 pb-8" v-else>
        <v-progress-circular
            indeterminate
            size="80"
            color="primary"
        ></v-progress-circular>
      </v-layout>
    </v-row>
    <v-dialog
        :fullscreen="$vuetify.breakpoint.xsOnly"
        v-model="dialog"
        width="500"
        persistent
        eager
    >
      <v-card>
        <v-card-title
            class="headline grey lighten-2"
            primary-title
        >
          Neue Telefonnummer verifizieren
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon>
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-lazy>
          <phone-wizard v-on:successful="dialog = false" :visible="dialog"></phone-wizard>
        </v-lazy>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import {mapGetters} from "vuex";
import { mdiClose} from '@mdi/js'


export default {
  name: "PhoneSelector",
  components: {PhoneWizard : () => import("./PhoneWizard")},
  computed: {
    ...mapGetters({
      user: 'userStore/user',
      phoneNumber: 'userStore/phoneNumber',
    }),
    buttonText() {
      if(this.user.loggedIn && !this.phoneNumber) {
        return "Telefonnummer hinzufügen"
      } else {
        return "Telefonnummer bearbeiten"
      }
    }
  },
  props:{
    noCurrentTelString:Boolean,
  },
  data() {
    return {
      mdiClose:mdiClose,
      phoneNumberLoading:false,
      dialog: false
    };
  },
  methods: {
    deletePhoneNumber(){
      this.phoneNumberLoading = true
      firebase.auth().currentUser.unlink('phone').then((newUser)=>{
        this.phoneNumberLoading = false
        this.$store.commit('userStore/SET_USER', {
          email: newUser.email,
          avatar: newUser.photoURL,
          uid: newUser.uid,
          phoneNumber: newUser.phoneNumber
        });

      })
    },
  },
}
</script>

<style scoped>

</style>
